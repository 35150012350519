import React from "react";
import '../styles/competition.scss'
import {useNavigate} from "react-router-dom";

const Competition = () => {
    const navigate = useNavigate()
    
    const handleNavigate = (e) => {
        window.scrollTo({
            top: 0,
            left: 0
        })
        navigate(`${e.target.id}`)
    }

    
    return (
        <div className="ContentContainer">
            <div className="TopLink">
                <div className="Link" id="/" onClick={handleNavigate}>Главная</div>
                <div>&nbsp;/ Конкурсы</div>
            </div>
            <div className="AboutContainer PB0">
                <div className="AboutPanel">
                    <h1>Конкурсы</h1>
                    <hr className="MT30"></hr>
                    {/* Newest competition */}
                    <h2 className="MT20">конкурс на графических планшетах для детей 7-11 лет: <span>"Рисуем мечту!"</span> ко Дню Знаний - 2024.</h2>
                    <h3 className="MT30">Цель:</h3>
                    <p>
                        Развить у детей интерес к цифровому искусству, познакомить с основами работы на графическом планшете, поощрить креативность и фантазию.
                    </p>
                    <p>
                        Возрастная группа: 7-11 лет.
                    </p>
                    <p>
                        Участие бесплатное.
                    </p>
                    <h3 className="MT30">Задачи:</h3>
                    <p>
                        * Познакомить детей с основами работы на графическом планшете.
                    </p>
                    <p>
                        * Развить навыки рисования с помощью цифровых инструментов.
                    </p>
                    <p>
                        * Стимулировать творчество и фантазию.
                    </p>
                    <p>
                        * Поощрить использование ярких цветов, интересных форм и нестандартных идей.
                    </p>
                    <h2 className="MT20">Этапы конкурса:</h2>
                    <h3 className="MT30">1. Регистрация:</h3>
                    <p>
                        * Зарегистрироваться на конкурсе на сайте : <a href="https://www.innosfera-almet.ru">https://www.innosfera-almet.ru</a> 
                        или в социальных сетях: <a href="https://vk.com/innosphera_almet">https://vk.com/innosphera_almet</a>, 
                        <a href="https://t.me/innosphera_almet">https://t.me/innosphera_almet</a>,
                         <a href="https://2gis.ru/almetevsk/geo/70000001075449708">https://2gis.ru/almetevsk/geo/70000001075449708</a>
                    </p>
                    <p>
                        * Возрастная категория (7-9 лет, 10-11 лет).
                    </p>
                    <p>
                        * Заполнить анкету участника.
                    </p>

                    <h3 className="MT30">2. Подготовка работы:</h3>
                    <p>
                        * Использовать графический планшет для создания рисунка на тему: "Рисуем мечту!".
                    </p>
                    <p>
                        * Можно использовать любые программы для рисования (например, Paint, Krita, MediBang Paint и т.д.).
                    </p>
                    <p>
                        * Уделить внимание композиции, использованию цвета, и интересным деталям.
                    </p>
                    <p>
                        * Подготовить работу в цифровом формате (изображение в формате JPEG, PNG).
                    </p>

                    <h3 className="MT30">3. Отправка работы:</h3>
                    <p>
                        * Отправить готовую работу через форму на сайте или через электронную почту: <a href="mailto:vitrinа0602@mail.ru&subject=Конкурс: Рисуем мечту">vitrinа0602@mail.ru</a>.
                    </p>
                    <p>
                        * Работы принимаем с 5 по 25 августа 2024г.
                    </p>

                    <h3 className="MT30">3. Оценка работ:</h3>
                    <p>
                        * Работы оцениваются жюри из профессиональных художников и преподавателей творческих вузов.
                    </p>
                    <p>
                        <strong>Критерии оценки:</strong>.
                    </p>
                    <p>
                        - Творческий подход и оригинальность.
                    </p>
                    <p>
                        - Использование цвета, композиции и деталей.
                    </p>
                    <p>
                        - Техническое исполнение и владение инструментами графического планшета.
                    </p>
                    <p>
                        - Соответствие теме конкурса.
                    </p>
                    <p>
                        - Работа должна быть уникальной..
                    </p>
                    <h3 className="MT30">4. Награждение победителей:</h3>
                    <p>
                        * Победители в каждой возрастной категории (первое, второе и третье места).
                    </p>
                    <p>
                        * Награждение победителей ценными подарками и дипломами.
                    </p>
                    <p>
                        * Церемония награждения с участием жюри, партнеров и родителей 30августа 2024г.17.00.
                    </p>

                    <h3 className="MT30">дополнительные активности:</h3>
                    <p>
                        * Мастер-классы по работе с графическим планшетом от профессиональных художников.
                    </p>
                    <p>
                        * Выставка лучших работ участников конкурса.
                    </p>
                    <p>
                        * Онлайн-голосование за лучшую работу конкурса.
                    </p>
                    <p>
                        Этот конкурс поможет детям открыть для себя новый мир цифрового искусства, развить свои творческие способности и получить незабываемые впечатления.
                    </p>

                    <h3 className="MT30">Организатор конкурса АНО «НРЦ «Инносфера» г. Альметьевск</h3>
                    <p>
                        Телефон для справок: <a href="tel:+79872390254">89872390254</a>.
                    </p>


                    {/* Old competition */}
                    <hr className="MT30"></hr>
                    <div className="MT30"><a className="ItemTip MT30 LinkLined" href="https://vk.com/wall92197628_2736" rel="noreferrer" target="_blank">https://vk.com/wall92197628_2736</a></div> 
                    <h2 className="MT20">Внимание жители г. Альметьевск!</h2>
                    <p>АНО НРЦ "Инносфера" приглашает детей на увлекательные и разнообразные занятия и кружки! Мы находимся по адресу:</p>
                    <p>г.Альметьевск, ул. Белоглазова 20 (территория СОШ 2). Здание с вывеской "Инносфера"за футбольным полем 😊</p>
                    <p>г.Альметьевск, ул. Нефтяников 41 (территория СОШ 11)</p>
                    <h3 className="MT30">У нас вы найдете следующие направления:</h3>
                    <p>
                        🩰 Хореография (7+)
                    </p>
                    <p>
                        🥋 Карате Кекушинкай (3+)
                    </p>
                    <p>
                        💻 IT-развитие (программирование, цифровое творчество, работа на графических планшетах) (5+, 7+)
                    </p>
                    <p>
                        🧱 Лего конструирование на основе легороботов (5+)
                    </p>
                    <p>
                        🎨 Арт студия "Рисовашки" (5+)
                    </p>
                    <p>
                        🎨👩‍🎨 Мастер классы по рисованию для взрослых (18+)
                    </p>
                    <p>
                        🎭 Занятия по развитию творческого мышления (5+)
                    </p>
                    <p>
                        🇬🇧 Английский язык (7+)
                    </p>
                    <p>
                        🎓 Репетиторство для начальных и старших классов (7+)
                    </p>
                    <p>
                        📚 Подготовка к ОГЭ
                    </p>
                    <p>
                        🎯 Предметный репетитор
                    </p>
                    <p>
                        🔍 Профориентация (7-9 классы)
                    </p>
                    <p>
                        🧠 -Логопеды, -Нейропсихологи, -Психологи, -Нейропедагоги, -Дефектологи.
                    </p>
                    <p>
                        🌈 Развитие творческого мышления и интуитивных способностей (7+)
                    </p>
                    <p>
                        📰 Курс "Журналистика, видеомонтаж, ораторское искусство" (12+)
                    </p>
                    <p>
                        🎓 Курс "Стань психологом за 2 дня" (18+)
                    </p>
                    <p>Используйте возможность обогатить своего ребенка новыми навыками и знаниями! Запишитесь прямо сейчас по телефону: <a href="tel:+79872297205" className="GreenLink">+7 (987) 229-72-05</a>.</p>
                    <p>Доверьте развитие вашего ребенка профессионалам! Мы поможем ему раскрыть свой потенциал и достичь новых высот!</p>
                    <p>АНО НРЦ "Инносфера" - забота о будущем вашего ребенка!</p>
                    <iframe className="VideoComp MT30" title="Video" src="https://vk.com/video_ext.php?oid=92197628&id=456239630&hash=222ca2d13717809c" width="640" height="360" allow="autoplay; encrypted-media; fullscreen; picture-in-picture"></iframe>
                    <hr className="MT30"></hr>
                    <div className="MT30"><a className="ItemTip MT30 LinkLined" href="https://vk.com/wall-170666333_499" rel="noreferrer" target="_blank">https://vk.com/wall-170666333_499</a></div> 
                    <h2 className="MT20">Уважаемый Рустем Шафагатович !</h2>
                    <p>Мы выражаем вам искреннюю благодарность за вашу спонсорскую помощь в проведении конкурса "Создай свой сайт" среди молодежи города и района. В конкурсе приняли участие 43 человека в возрасте от 14 до 22 лет, которые представили свои работы в рамках заочного тура по трем номинациям. Из них было выбрано 12 финалистов, которые приняли участие в очном туре 20 октября 2023 года. Победителями стали студенты КФУ, политехнического колледжа: Алакаева Ксения Сергеевна, Камалова Анвар Русланович и Батырова Анастасия Юрьевна. Они были награждены денежными премиями.</p>
                    <p>Хотели бы отметить, что экспертами и руководителями конкурса были студенты КАИ, включая Шамсиева Эльдара Халиловича. Благодаря вашей спонсорской помощи, они также получили гонорары за свою работу.</p>
                    <p>От имени нашего коллектива и всех участников конкурса хотим выразить вам огромную благодарность. Для всех участников будут организованы дополнительные бесплатные мастер-классы, проводимые студентами КАИ.</p>
                    <p>С уважением,</p>
                    <p className="MT0">Организационный комитет конкурса "Создай свой сайт"</p>
                    <p>Директор</p>
                    <p className="MT0">АНО «НРЦ «Инносфера» Кузнецова Л.А.</p>
                    <iframe className="VideoComp MT30" title="Video" src="https://vk.com/video_ext.php?oid=-170666333&id=456239048&hash=49eae2e55dde1e08" width="640" height="360" allow="autoplay; encrypted-media; fullscreen; picture-in-picture"></iframe>
                </div>
            </div>
        </div>
    )
}

export default Competition