import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

import '../styles/about.scss'
import { fetchDocuments, fetchTeachers } from "../http/innoAPI";

// const teachers = [
//     {name: 'Иванова Ирина Владимировна', position: 'Директор', education: 'Высшее педагогическое образование, кандидат педагогических наук', experience: 'Стаж работы в образовании 20 лет', description: 'Основатель и руководитель научно-развивающего центра «Инносфера»'},
//     {name: 'Смирнова Анна Владимировна', position: 'Заведующая', education: 'Высшее педагогическое образование, кандидат педагогических наук', experience: 'Стаж работы в образовании 20 лет', description: 'Заведующая научно-развивающего центра «Инносфера»'},
//     {name: 'Петрова Ольга Владимировна', position: 'Учитель-логопед', education: 'Высшее педагогическое образование, кандидат педагогических наук', experience: 'Стаж работы в образовании 20 лет', description: 'Учитель-логопед научно-развивающего центра «Инносфера»'},
// ]

const About = () => {
    const navigate = useNavigate()
    const [teachers, setTeachers] = useState([])
    const [documents, setDocuments] = useState([])

    const handleNavigate = (e) => {
        if (e.key && e.key !== 'Enter') {
            return
        }
        window.scrollTo({
            top: 0,
            left: 0
        })
        navigate(`${e.target.id}`)
    }

    useEffect(() => {
        fetchTeachers().then(data => setTeachers(data))
        fetchDocuments().then(data => setDocuments(data))
    }, [])

    return (
        <section className="ContentContainer">
            <div className="TopLink">
                <div className="Link" id="/" onClick={handleNavigate} onKeyDown={handleNavigate} tabIndex={0} aria-roledescription="На главную">Главная</div>
                <div>&nbsp;/ О нас</div>
            </div>
            <div className="AboutContainer PB0">
                <div className="AboutPanel">
                    <h1>О научно-развивающем центре «Инносфера»</h1>
                    {/*<p>В нашем центре работают специалисты , опыт и квалификация которых, позволяет грамотно и целенаправленно, в комплексном подходе с лучшими нейропедагогическими , научно-доказанными методами развивать наших детей. Мы предлагаем не просто методы, важные для психолого-педагогической системы в обучении, воспитании и развитии современных детей, а инновационную, т.е. технологии, которые действительно преобразуют педагогический процесс, где используются новшества наших новаторов, направленных на совершенствование образовательного и воспитательного процесса. В отобранных нами методиках присутствует мотивация, развитие креативности, эмоционального интеллекта, и самое главное, стремление к самосовершенствованию и успеху.</p>*/}
                    {/*<p>К успеху и развитию нас ведет наше КПД, которое можно и нужно развивать с детского возраста. Основная цель в развитии ребенка - "вовремя успеть сформировать способности, раскрыть интеллектуальный и физический потенциал ребенка и сделать его не только одаренным, а успешным, умеющим развиваться самостоятельно". К сожалению, педагоги поверхностно оценили открытия и исследования в области образования и воспитания наших выдающихся ученых века. Это Шалва Амоношвили, Павел Тюленев, Николай Зайцев, Ю. Б. Гатанов, А. Стрельникова, Михаил Щетинин, Татьяна Черниговская, А.Могучий, Ю. Б. Гипенейтер и т.д.</p>*/}
                    <h2>Научно -развивающий Центр инновационного  развития и творчества:  где детство становится  ярким!</h2>
                    <p>В эпоху стремительного развития технологий и информации,  дети  нуждаются  в  новых  пространствах  для  творческого  развития.  Развивающий Центр- это  именно  такое  пространство,  которое  объединяют  в  себе , инновационные  программы  и  возможности  для  творческой  реализации.</p>
                    <h2>Миссия Центра:</h2>
                    <p>Наша миссия -  вдохновлять  детей  на  познавательный  поиск,  развивать  их  творческий  потенциал,  формировать  любознательность  и  стремление  к  самореализации.  Мы  стремимся помочь детям ,используя современные знания в нейропедагогике.</p>
                    <h2>Концепция Центра:</h2>
                    <h2>Центр  представляет  собой  инновационное  пространство,  где  дети  могут:</h2>

                    <p>• &nbsp; Исследовать</p>
                    <p>• &nbsp; Действовать</p>
                    <p>• &nbsp; Применить  полученные  знания  на  практике,  участвуя  в  творческих  мастер-классах,  проектах  и  конкурсах</p>
                    <p>• &nbsp; Творить:  Развивать  свой  творческий  потенциал,  занимаясь  изобразительным  искусством,  музыкой,  театром,  литературным  творчеством  и  другими  видами  искусства</p>
                    <h2>Преимущества Центра:</h2>
                    <p>• &nbsp; Интеграция форматов</p>
                    <p>• &nbsp; Развитие талантов:  Специальные  программы  для  одаренных  детей  и  детей  с  особыми  потребностями</p>
                    <p>• &nbsp; Открытое пространство:  Центр  открыт  для  всех  желающих,  независимо  от  возраста  и  уровня  подготовки</p>
                    <h2>Научно-развивающий центр  это  не  просто  место  для развития.  Это  пространство,  где  закладываются  фундаменты  будущего,  где  дети  и  молодежь  находят  свои  пути  в  жизнь  и  становятся  успешными  личности.</h2>

                    <h2 className="MT30">К нам обращаются за помощью родители детей</h2>
                    <h2 className='MB0'>от 2 до 7 лет с:</h2>
                    <p>• &nbsp; детским аутизмом, атипичным аутизмом, синдромом Аспергера, другими дезинтегративными расстройствами и общими расстройствами развития;</p>
                    <p>ОНР различного уровня развития (моторная алалия, сенсорная алалия, различные формы дизартрии);</p>
                    <p>• &nbsp; нарушением интеллектуального развития (умственная отсталость);</p>
                    <p>• &nbsp; задержкой психического развития (ЗПР);</p>
                    <p>• &nbsp; задержкой речевого развития (ЗРР);</p>
                    <p>• &nbsp; нарушением пищевого поведения (отказ от твердой пищи, трудности с жеванием и глотанием, рвотный рефлекс на твердую и/или холодную пищу);</p>
                    <p>• &nbsp; первазивными нарушениями развития в результате различных метаболических синдромов;</p>
                    <p>• &nbsp; гинетическими синдромами;</p>
                    <p>• &nbsp; ДЦП;</p>
                    <p>• &nbsp; СДВГ;</p>
                    <p>• &nbsp; афазии;</p>
                    <p>• &nbsp; дислалии;</p>
                    <p>• &nbsp; дисграфии и дислексии;</p>
                    <p>• &nbsp; нарушением звукопроизношения;</p>
                    <p>• &nbsp; заиканием;</p>
                    <p>• &nbsp; нарушением памяти и концентрации внимания, успеваемости в школе;</p>
                    <p>• &nbsp; с нарушениями физического развития (мелкая и крупная моторика, ловкость, ритмичность, координация движений);</p>
                    <p>• &nbsp; нарушениями обработки сенсорной информации.</p>
                    <h2 className='MT30 MB0'>от 7 до 14 лет:</h2>
                    <p>• &nbsp; дети с ОВЗ;</p>
                    <p>• &nbsp; дети с ментальными нарушениями;</p>
                    <p>• &nbsp; дети, отстающие в учебе;</p>
                    <p>• &nbsp; социально-не адаптированные;</p>
                    <p>• &nbsp; дети с ДЦП, со слабо-мышечной активностью.</p>
                    <h2 className="MT30">Какие задачи мы решаем в результате реализации наших коррекционных программ в «Инносфере»:</h2>
                    <p>• &nbsp; Ранняя диагностика нарушений и выявления проблемных зон развития у ребенка на основании квалифицированного психолого-педагогического обследования и сбора анамнеза.</p>
                    <p>• &nbsp; Запуск и развитие речи у неговорящего ребенка с органическими поражениями ЦНС.</p>
                    <p>• &nbsp; Обучение ребенка есть из ложки, пить из чашки. Появление у ребенка первых лепетных звуков.</p>
                    <p>• &nbsp; Расширение пищевого рациона и введение твердой пищи. Предупреждение в раннем возрасте последующего развития речевых нарушений.</p>
                    <p>• &nbsp; Развитие артикуляционного аппарата.</p>
                    <p>• &nbsp; Развитие речевой коммуникации и речевого поведения у ребенка с аутизмом.</p>
                    <p>• &nbsp; Развитие и увеличение диапазона невербальных коммуникаций у ребенка.</p>
                    <p>• &nbsp; Введение альтернативных способов коммуникаций.</p>
                    <p>• &nbsp; Коррекция нежелательного поведения, в том числе: снижение и контроль приступов агрессии и самоагрессии, повышенной тревожности, гипер- или гипочувствительности, аутостимуляций и стереотипного поведения.</p>
                    <p>• &nbsp; Развитие функций и навыков социального контакта ребенка со сверстниками, развитие эмоциональной выразительности и вовлеченности в групповую деятельность.</p>
                    <p>• &nbsp; Развитие навыков игровой деятельности, в том числе сюжетно-ролевых игр.</p>
                    <p>• &nbsp; Улучшение саморегуляции: улучшение качества сна, снижение сенсорных защит (аудиальной, тактильной, проприоцептивной, вестибулярной).</p>
                    <p>• &nbsp; Включение ребенком своих сенсорных ощущений в контекст осмысленных, самостоятельных, адаптивных форм взаимодействия с окружающей средой.</p>
                    <p>• &nbsp; Интеграция неугасших первичных базовых рефлексов, препятствующих общему нейромоторному развитию у ребенка.</p>
                    <p>• &nbsp; Развитие крупной и мелкой моторики, выносливости, ловкости, подвижности, улучшение контроля своих движений и управляемости ими.</p>
                    <p>• &nbsp; Развитие когнитивных функций.</p>
                    <p>• &nbsp; Развитие слухоречевой и зрительной памяти, концентрации внимания, логического мышления, повышение успеваемости в школе.</p>
                    <p>• &nbsp; Расширение словарного запаса и исключение из речи аграмматизмов.</p>
                    <p>В своей работе мы руководствуемся современной новейшей безмедикаментозной коррекцией нарушений различной этиологии. В своей работе мы используем современные аппараты нейрокоррекции как мозжечковую стимуляцию, транслингвальную стимуляцию, дыхательные упражнения, нейропсихологические упражнения Колгановой, занятия по развитию творческих задатков по методу Дж. Гилфорда и ДЖ. Рензулли, развитие эмоционального интеллекта.
                        Все наши коррекционные занятия направлены на развитие умений и навыков, преодоление трудностей поведения, общения, взаимодействия, коммуникации и т.д. Мы работаем с детьми совместно с их родителями, ставим глобальные цели и конкретные задачи по коррекции нарушений, социализации, развитию речи, интеллектуальных, коммуникативных навыков.</p>
                    {teachers && teachers.length > 0 &&
                        <>
                            <h1 className="MT50">Педагогический состав</h1>
                            {teachers.map((teacher, index) => {
                                return (
                                    <div key={index} className="TeacherContainer">
                                        {teacher.img &&
                                            <img src={process.env.REACT_APP_API_URL + teacher.img} alt="" />
                                        }
                                        <div className="TeacherInfo">
                                            <div className="TeacherName">{teacher.name}</div>
                                            <p className="TeacherPosition"><span>Должность: </span>{teacher.position}</p>
                                            <p className="TeacherEducation"><span>Образование: </span>{teacher.education}</p>
                                            <p className="TeacherExperience"><span>Опыт работы: </span>{teacher.experience}</p>
                                            <p className="TeacherDescription">{teacher.description}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    }
                    {documents && documents.length > 0 &&
                        <>
                            <h1 className="MT50">Документы и отчеты</h1>
                            {documents.map((item, index) => {
                                return (
                                    <div key={index} className="DocumentItem">
                                        <a className="DocumentName" href={process.env.REACT_APP_API_URL + item.document} rel="noreferrer" target="_blank">{item.name}</a>
                                        <div className="DocumentDesc">{item.text}</div>
                                    </div>
                                )
                            })}
                        </>
                    }
                </div>
            </div>
        </section>
    )
}

export default About;