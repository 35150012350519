import React from "react";

const Charity = () => {
    return (
        <div>
        <h1>Charity</h1>
        </div>
    );
}

export default Charity;